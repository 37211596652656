import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { BsChevronDown, BsChevronLeft, BsChevronRight } from 'react-icons/bs'

import { PxToVw } from '@/utils/convertPx'
import { theme } from '@/styles/theme'

type Props = {
  children: ReactNode
  showArrowLeft?: boolean
  showArrowRight?: boolean
  showArrowDown?: boolean
  bgColor?: string
  type?: 'button' | 'submit' | 'reset'
  onClick?: () => void
}

export const Button = ({
  children,
  showArrowLeft,
  showArrowRight,
  showArrowDown,
  bgColor = `${theme.colors.supayBlue}`,
  type = 'button',
  onClick,
}: Props) => {
  return (
    <ButtonStyle
      bgColor={bgColor}
      showArrowLeft={showArrowLeft}
      type={type}
      onClick={onClick}
    >
      {showArrowLeft && <ArrowLeft size={25} />}
      {children}
      {showArrowRight && <ArrowRight size={25} />}
      {showArrowDown && <ArrowDown size={25} />}
    </ButtonStyle>
  )
}

const ButtonStyle = styled.button<{
  bgColor: string
  showArrowLeft?: boolean
}>`
  background-color: ${({ bgColor }) => bgColor};
  border-radius: 37px;
  height: 62px;
  padding: 19px 33px;
  color: #ffffff;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: ${({ showArrowLeft }) =>
    showArrowLeft ? 'flex-start' : 'space-between'};

  &:hover {
    opacity: ${({ theme }) => theme.opacity};
  }

  @media screen and (max-width: 1047px) {
    font-size: 18px;
    height: 56px;
    padding: 19px 27px;
  }

  @media screen and (max-width: 450px) {
    width: 75vw;
    font-size: ${PxToVw(20, 375)};
  }

  @media screen and (max-width: 370px) {
    font-size: ${PxToVw(18, 375)};
  }
`

const ArrowLeft = styled(BsChevronLeft)`
  margin-right: 28px;

  @media screen and (max-width: 450px) {
    margin-right: 19px;
  }
`

const ArrowRight = styled(BsChevronRight)`
  margin-left: 28px;

  @media screen and (max-width: 450px) {
    margin-left: 0;
  }
`

const ArrowDown = styled(BsChevronDown)`
  margin-left: 28px;

  @media screen and (max-width: 450px) {
    margin-left: 0;
  }
`
