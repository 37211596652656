import React, { ReactNode } from 'react'
import styled from 'styled-components'

type FormLabelProps = {
  isRequired?: boolean
  children: ReactNode
}

export const FormLabel = ({ isRequired = false, children }: FormLabelProps) => {
  return (
    <Wrapper>
      <FormBadge isRequired={isRequired}>
        {isRequired ? '必須' : '任意'}
      </FormBadge>
      <Label>{children}</Label>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;

  @media screen and (max-width: 1047px) {
    margin-top: 16px;
  }
`

const FormBadge = styled.div<FormLabelProps>`
  line-height: 1;
  font-size: 14px;
  color: white;
  padding: 7px 12px;
  border-radius: 10px;
  background-color: ${({ isRequired, theme }) =>
    isRequired ? theme.colors.supayBlue : '#bac1c9'};

  @media screen and (max-width: 1047px) {
    font-size: 12px;
    padding: 4px 7px;
    border-radius: 6px;
    min-width: 38px;
  }
`

const Label = styled.p`
  font-size: 18px;
  margin-left: 13px;
  width: 213px;
  vertical-align: top;
  line-height: 1.5;

  @media screen and (max-width: 1047px) {
    font-size: 14px;
    margin-left: 8px;
    width: auto;
    vertical-align: middle;
  }
`
