import { ActionTypes } from '@/store/actionTypes'
import {
  ContactsPhoneChangeActionTypes,
  ContactPhoneChange,
} from '@/store/contactPhoneChange/types'

export const setContactPhoneChange = (
  payload: ContactPhoneChange
): ContactsPhoneChangeActionTypes => {
  return {
    type: ActionTypes.contactPhoneChange,
    payload,
  }
}

export const clearState = () => {
  return {
    type: ActionTypes.clearState,
  }
}
