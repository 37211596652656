import React, { ReactNode } from 'react'
import styled from 'styled-components'

type FormSubLabelProps = {
  htmlFor?: string
  children: ReactNode
}

export const FormSubLabel = ({ htmlFor, children }: FormSubLabelProps) => {
  return (
    <FormSubLabelStyle>
      <label htmlFor={htmlFor}>{children}</label>
    </FormSubLabelStyle>
  )
}

const FormSubLabelStyle = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 12px;
  line-height: 1;

  @media screen and (max-width: 1047px) {
    font-size: 14px;
    margin-bottom: 10px;
  }
`
