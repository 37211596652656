import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Layout } from '@/components/layouts/Layout'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '@/store/index'
import { navigate } from 'gatsby'
import { STATUS } from '@/constants/status'
import { clearState } from '@/store/contactPhoneChange/action'
import useFormInput from '@/hooks/useFormInput'
import axios from 'axios'
import { Seo } from '@/components/Seo'
import { BreadCrumbs } from '@/components/layouts/Breadcrumbs'
import { Title } from '@/components/elements/Title'
import { theme } from '@/styles/theme'
import {
  ConfirmFormAction,
  FileInfo,
  FormContent,
  FormMain,
  FormSubGroup,
  FormSubItem,
} from '@/styles/form'
import { Button } from '@/components/elements/Button'
import { MainWrapper, TitleWrapper } from '@/styles/common'
import { FormLabel } from '@/components/layouts/Form/FormLabel'
import { FormSubLabel } from '@/components/layouts/Form/FormSubLabel'

export default function phoneChangeConfirm() {
  const breadCrumbs: {
    name: string
    path?: string
  }[] = [
    {
      name: 'トップ',
      path: '/',
    },
    {
      name: 'お問い合わせ',
      path: '/contact/',
    },
    {
      name: '入力内容のご確認',
    },
  ]

  const [isLoading, setIsLoading] = useState(false)

  const form = useSelector((state: RootState) => state.contactPhoneChange)
  const dispatch = useDispatch()
  const { dateParseString } = useFormInput()

  const handlePopstate = () => {
    history.pushState(null, '', null)
    dispatch(clearState())
  }

  useLayoutEffect(() => {
    const isReload = sessionStorage.getItem('isReload')
    if (isReload && isReload !== '') {
      navigate('/contact/phone-change/')
    }
  }, [])

  const onUnload = () => {
    sessionStorage.setItem('isReload', '1')
  }

  useEffect(() => {
    window.addEventListener('popstate', handlePopstate, false)

    return () => {
      window.removeEventListener('popstate', handlePopstate, false)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('beforeunload', onUnload, false)

    return () => {
      sessionStorage.setItem('isReload', '')
      window.removeEventListener('beforeunload', onUnload, false)
    }
  })

  const modify = () => {
    navigate('/contact/phone-change/')
  }

  const formStatus = (flg: string) => {
    switch (flg) {
      case STATUS.HAD:
        return 'あり'
      case STATUS.NONE:
        return 'なし'
      case STATUS.NOT_SURE:
        return 'わからない'
    }
  }

  const sendForm = () => {
    setIsLoading(true)

    let fileArr: File[] = []
    if (form.other_files) {
      const otherFileArr: File[] = form.other_files
      fileArr = form.require_files.concat(otherFileArr)
    } else {
      fileArr = form.require_files
    }

    const submitData = new FormData()
    submitData.append('mail_address', form.email)
    submitData.append('new_phone', form.new_phone)
    submitData.append('new_account_status', form.new_account_status)
    form.new_account_status === STATUS.HAD &&
      submitData.append('target_member_id', form.new_memberid)
    submitData.append('old_phone', form.old_phone)
    submitData.append('current_member_id', form.old_memberid)
    submitData.append('bank_account_status', form.bank_status)
    if (form.bank_status === STATUS.HAD) {
      submitData.append('bank_name', form.bank_name)
      submitData.append('branch_name', form.branch_name)
    }
    submitData.append('charge_history_status', form.charge_history_status)
    if (form.charge_history_status === STATUS.HAD) {
      submitData.append(
        'charge_history_date',
        dateParseString(form.charge_history_date)
      )
      submitData.append('charge_history_money', form.charge_history_money)
    }
    submitData.append('transaction_history_status', form.payment_history_status)
    if (form.payment_history_status === STATUS.HAD) {
      submitData.append('transaction_history_money', form.payment_history_money)
      submitData.append(
        'transaction_history_date',
        dateParseString(form.payment_history_date)
      )
      submitData.append('transaction_history_store', form.payment_history_store)
    }
    submitData.append('supay_balance_status', form.supay_balance_status)
    form.supay_balance_status === STATUS.HAD &&
      submitData.append('supay_balance', form.supay_balance)
    submitData.append('profile_status', form.profile_status)
    if (form.profile_status === STATUS.HAD) {
      submitData.append('name', form.profile_name)
      submitData.append(
        'birthday',
        dateParseString(form.profile_birthday, false)
      )
    }
    fileArr.map((file) => submitData.append('files[]', file))

    axios
      .post('/phone-change', submitData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        dispatch(clearState())
        setIsLoading(false)
        if (response.status == 200) {
          navigate('/contact/phone-change/done/')
        } else {
          navigate('/contact/error/')
        }
      })
      .catch((e) => {
        setIsLoading(false)
        navigate('/contact/error/')
      })
  }

  return (
    <Layout isLoading={isLoading} isForm>
      <Seo
        title="お問い合わせ内容確認 - お問い合わせ"
        canonical="https://su-pay.jp/contact/phone-change/confirm/"
        noIndex
      />
      <Title as="h1" bg={true}>
        お問い合わせ
      </Title>
      <MainWrapper>
        <BreadCrumbs breadCrumbs={breadCrumbs} />
        <TitleWrapper>
          <Title as="h2" color={theme.colors.supayBlue}>
            入力内容のご確認
          </Title>
        </TitleWrapper>
        <FormMain>
          <FormLabel isRequired>ご連絡先メールアドレス</FormLabel>
          <FormContent>
            <p>{form.email}</p>
          </FormContent>
          <FormLabel isRequired>
            移行先の電話番号(新携帯電話番号)の情報
          </FormLabel>
          <FormContent>
            <FormSubGroup>
              <FormSubItem>
                <FormSubLabel>移行先の電話番号(新携帯電話番号)</FormSubLabel>
                <p>{form.new_phone}</p>
              </FormSubItem>
              <FormSubItem>
                <FormSubLabel>
                  移行先の電話番号(新携帯電話番号)でのSU-PAYアカウント作成状況
                </FormSubLabel>
                <p>
                  {form.new_account_status === STATUS.HAD
                    ? '作成済み'
                    : '未作成'}
                </p>
              </FormSubItem>
              {form.new_account_status === STATUS.HAD && (
                <FormSubItem>
                  <FormSubLabel>会員番号</FormSubLabel>
                  <p>{form.new_memberid}</p>
                </FormSubItem>
              )}
            </FormSubGroup>
          </FormContent>
          <FormLabel isRequired>
            移行元の電話番号(旧携帯電話番号)のSU-PAYアカウント情報
          </FormLabel>
          <FormContent>
            <FormSubGroup>
              <FormSubItem>
                <FormSubLabel>移行元の電話番号(旧携帯電話番号)</FormSubLabel>
                <p>{form.old_phone}</p>
              </FormSubItem>
              <FormSubItem>
                <FormSubLabel>会員番号</FormSubLabel>
                <p>{form.old_memberid}</p>
              </FormSubItem>
              <FormSubItem>
                <FormSubLabel>銀行口座登録情報</FormSubLabel>
                <p>{formStatus(form.bank_status)}</p>
              </FormSubItem>
              {form.bank_status === STATUS.HAD && (
                <>
                  <FormSubItem>
                    <FormSubLabel>金融機関名</FormSubLabel>
                    <p>{form.bank_name}</p>
                  </FormSubItem>
                  <FormSubItem>
                    <FormSubLabel>支店名</FormSubLabel>
                    <p>{form.branch_name}</p>
                  </FormSubItem>
                </>
              )}
            </FormSubGroup>
          </FormContent>
          <FormLabel isRequired>最新のチャージ履歴</FormLabel>
          <FormContent>
            <FormSubGroup>
              <FormSubItem>
                <p>{formStatus(form.charge_history_status)}</p>
              </FormSubItem>
              {form.charge_history_status === STATUS.HAD && (
                <>
                  <FormSubItem>
                    <FormSubLabel>チャージ日</FormSubLabel>
                    <p>{dateParseString(form.charge_history_date)}</p>
                  </FormSubItem>
                  <FormSubItem>
                    <FormSubLabel>チャージ金額（円）</FormSubLabel>
                    <p>{form.payment_history_money}円</p>
                  </FormSubItem>
                </>
              )}
            </FormSubGroup>
          </FormContent>
          <FormLabel isRequired>決済履歴</FormLabel>
          <FormContent>
            <FormSubGroup>
              <FormSubItem>
                <p>{formStatus(form.payment_history_status)}</p>
              </FormSubItem>
              {form.payment_history_status === STATUS.HAD && (
                <>
                  <FormSubItem>
                    <FormSubLabel>決済日</FormSubLabel>
                    <p>{dateParseString(form.payment_history_date)}</p>
                  </FormSubItem>
                  <FormSubItem>
                    <FormSubLabel>決済金額（円）</FormSubLabel>
                    <p>{form.payment_history_money}円</p>
                  </FormSubItem>
                  <FormSubItem>
                    <FormSubLabel>利用店舗</FormSubLabel>
                    <p>{form.payment_history_store}</p>
                  </FormSubItem>
                </>
              )}
            </FormSubGroup>
          </FormContent>
          <FormLabel isRequired>現在のSU-PAY残高</FormLabel>
          <FormContent>
            <FormSubGroup>
              <FormSubItem>
                <p>{formStatus(form.supay_balance_status)}</p>
              </FormSubItem>
              {form.supay_balance_status === STATUS.HAD && (
                <FormSubItem>
                  <FormSubLabel>SU-PAY残高</FormSubLabel>
                  <p>{form.supay_balance}円</p>
                </FormSubItem>
              )}
            </FormSubGroup>
          </FormContent>
          <FormLabel isRequired>個人情報（名前、生年月日等）の登録</FormLabel>
          <FormContent>
            <FormSubGroup>
              <FormSubItem>
                <p>{formStatus(form.profile_status)}</p>
              </FormSubItem>
              {form.profile_status === STATUS.HAD && (
                <>
                  <FormSubItem>
                    <FormSubLabel>名前</FormSubLabel>
                    <p>{form.profile_name}</p>
                  </FormSubItem>
                  <FormSubItem>
                    <FormSubLabel>生年月日</FormSubLabel>
                    <p>{dateParseString(form.profile_birthday, false)}</p>
                  </FormSubItem>
                </>
              )}
            </FormSubGroup>
          </FormContent>
          <FormLabel isRequired>本人確認書類</FormLabel>
          <FormContent>
            {form.require_files.map((file, i) => (
              <FileInfo key={`${file.name}${i}`}>
                <p>{file.name}</p>
              </FileInfo>
            ))}
          </FormContent>
          {form.other_files.length > 0 && (
            <>
              <FormLabel>その他の書類</FormLabel>
              <FormContent>
                {form.other_files.map((file, i) => (
                  <FileInfo key={`${file.name}${i}`}>
                    <p>{file.name}</p>
                  </FileInfo>
                ))}
              </FormContent>
            </>
          )}
        </FormMain>
        <ConfirmFormAction>
          <Button showArrowLeft bgColor="#D0D5DB" onClick={() => modify()}>
            戻る
          </Button>
          <Button showArrowRight onClick={() => sendForm()}>
            送信する
          </Button>
        </ConfirmFormAction>
      </MainWrapper>
    </Layout>
  )
}
