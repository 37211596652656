import { BaseCard } from '@/components/elements/BaseCard'
import styled from 'styled-components'

export const FormHeader = styled(BaseCard)`
  margin-bottom: 80px;

  @media screen and (max-width: 1047px) {
    margin-bottom: 44px;
  }
`

export const Caution = styled.div`
  padding: 33px 37px;
  font-size: 18px;

  h3 {
    font-size: 22px;
    margin-bottom: 19px;
  }

  div:first-of-type {
    margin-bottom: 30px;
  }

  ul {
    margin-bottom: 24px;

    li {
      &::before {
        content: '・';
        color: ${(props) => props.theme.colors.supayBlue};
        font-weight: bold;
        margin-right: 5px;
      }
    }

    ol {
      list-style: decimal;
      padding-left: 45px;

      li {
        &::before {
          content: '';
        }
      }
    }
  }

  .emphasize-red {
    color: ${({ theme }) => theme.colors.accent};
  }

  div:last-child {
    display: flex;
    gap: 5px;
  }

  @media screen and (max-width: 1047px) {
    padding: 19px 22px;
    font-size: 14px;

    h3 {
      font-size: 14px;
      margin-bottom: 13px;
    }

    div:first-of-type {
      margin-bottom: 14px;
    }

    ul {
      margin-bottom: 22px;

      ol {
        padding-left: 37px;
      }
    }
  }
`

export const FormMain = styled.div`
  display: grid;
  grid-template-columns: 280px 1fr;
  gap: 60px 83px;
  margin-bottom: 70px;

  @media screen and (max-width: 1047px) {
    grid-template-columns: 1fr;
    gap: 14px 0;
    margin-bottom: 40px;

    div:first-child {
      margin-top: 0px;
    }
  }
`

export const FormContent = styled.div`
  p {
    word-break: break-word;
    white-space: pre-wrap;
    line-height: 1.5;
  }

  textarea {
    padding: 15px 22px;
    resize: none;
    line-height: 1.5;
  }

  @media screen and (min-width: 1047px) {
    padding-top: 5px;

    p:first-child {
      margin-top: -5px;
    }
  }
`

export const FormSubGroup = styled.div`
  @media screen and (max-width: 1047px) {
    padding: 27px 23px;
    background-color: #f7f8f9;
    border-radius: 10px;
  }
`

export const FormNote = styled.ul`
  color: #738392;
  font-size: 16px;
  line-height: 24px;
  margin-top: 2px;

  li {
    display: flex;
    &::before {
      content: '※';
    }
  }

  @media screen and (max-width: 1047px) {
    font-size: 12px;
    line-height: 16px;
  }
`

export const FormInputStyle = styled.input<{
  as?: keyof JSX.IntrinsicElements
}>`
  display: inline-block;
  ${({ as }) => as !== 'textarea' && `height: 62px`};
  padding: 0 22px;
  font-size: 18px;
  border: 1px solid ${({ theme }) => theme.colors.borderGrey};
  background-color: ${({ theme }) => theme.colors.backgroundGrey};
  border-radius: 10px;
  width: 100%;

  &::placeholder {
    line-height: 1;
    color: #9ea8b3;
  }

  &:focus {
    border-color: #1c2f48;
  }

  &[type='number'] {
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  @media screen and (max-width: 1047px) {
    ${({ as }) => as !== 'textarea' && `height: 54px`};
    font-size: 16px;
  }
`

export const FormSubItem = styled.div`
  & + & {
    margin-top: 35px;
  }

  @media screen and (max-width: 1047px) {
    & + & {
      margin-top: 20px;
    }
  }
`

export const FormItemAlert = styled.p`
  font-size: 16px;
  color: ${(props) => props.theme.colors.accent};
  font-weight: bold;
  margin-top: 2px;

  & + ul {
    margin-top: 6px;
  }

  &::before {
    content: 'エラー：';
  }

  @media screen and (max-width: 1047px) {
    font-size: 12px;
  }
`

export const FormBtn = styled.div`
  display: inline-flex;
  align-items: center;
  height: 62px;
  padding: 0 22px;
  font-size: 18px;
  border: 1px solid ${({ theme }) => theme.colors.borderGrey};
  background-color: ${({ theme }) => theme.colors.backgroundGrey};
  border-radius: 10px;

  @media screen and (max-width: 1047px) {
    height: 54px;
    line-height: 54px;
    font-size: 16px;
  }
`

export const FormRadio = styled(FormBtn)`
  input {
    opacity: 0;
    appearance: none;
    position: absolute;
  }

  label {
    padding-left: 35px;
    position: relative;
  }

  label:before,
  label:after {
    content: '';
    display: block;
    border-radius: 50%;
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
  }

  label:before {
    background-color: #fff;
    border: 1px solid ${({ theme }) => theme.colors.borderGrey};
    border-radius: 50%;
    width: 18px;
    height: 18px;
    left: 1px;
  }

  label:after {
    background-color: #fff;
    border: 5px solid ${({ theme }) => theme.colors.supayBlue};
    border-radius: 50%;
    opacity: 0;
    width: 18px;
    height: 18px;
    left: 1px;
  }

  input:checked + label:after {
    opacity: 1;
  }

  input:checked + label {
    color: ${({ theme }) => theme.colors.supayBlue};
    font-weight: bold;
  }

  @media screen and (max-width: 1047px) {
    width: 100%;

    label {
      padding-left: 33px;
    }
  }
`

export const FormCheckbox = styled(FormBtn)`
  label {
    display: flex;
    align-items: center;
  }

  input[type='checkbox'] {
    margin: 0;
    padding: 0;
    background: none;
    border: none;
    border-radius: 0;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 0;
    height: 0;

    &:checked {
      & + span {
        color: ${({ theme }) => theme.colors.supayBlue};
        font-weight: bold;
      }

      & + span::after {
        content: '';
        display: block;
        position: absolute;
        border-bottom: 3px solid white;
        border-left: 3px solid white;
        height: 6px;
        width: 11px;
        transform: rotate(-45deg);
        top: 39%;
        left: 4px;
      }

      & + span::before {
        background-color: ${({ theme }) => theme.colors.supayBlue};
        border: none;
      }
    }
  }

  span {
    padding-left: 35px;
    position: relative;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      width: 18px;
      height: 18px;
      background-color: #fff;
      border: 1px solid ${({ theme }) => theme.colors.borderGrey};
      transform: translateY(-50%);
    }
  }

  @media screen and (max-width: 1047px) {
    width: 100%;

    input[type='checkbox']:checked + span::after {
      top: 42%;
    }
  }
`

export const FileUpload = styled(FormBtn)`
  min-width: 172px;
  margin-bottom: 16px;

  input[type='file'] {
    display: none;
  }

  &:hover {
    cursor: pointer;
    opacity: ${(props) => props.theme.opacity};
  }

  @media screen and (max-width: 1047px) {
    min-width: 158px;
    margin-bottom: 14px;
  }
`

export const NoFileSelected = styled.p`
  line-height: 1;

  @media screen and (max-width: 1047px) {
    font-size: 14px;
  }
`

export const FileInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  line-height: 1;

  & + & {
    margin-top: 9px;
  }

  @media screen and (max-width: 1047px) {
    gap: 6px;

    & + & {
      margin-top: 6px;
    }
  }
`

export const FileFormNote = styled(FormNote)`
  margin-top: 25px;

  @media screen and (max-width: 1047px) {
    margin-top: 14px;
  }
`

export const FormSubItemInner = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  @media screen and (max-width: 1047px) {
    flex-direction: column;
    gap: 10px;
  }
`

export const FormDatePicker = styled(FormBtn)`
  .react-datepicker {
    line-height: initial;
  }

  .react-datepicker__input-time-container {
    font-size: 18px;
  }

  input.react-datepicker-time__input {
    display: inline-block;
    height: 62px;
    line-height: 62px;
    padding: 0 22px;
    border: 1px solid ${({ theme }) => theme.colors.borderGrey};
    background-color: ${({ theme }) => theme.colors.backgroundGrey};
    border-radius: 10px;
  }

  .react-datepicker__navigation {
    top: 14px;
  }

  @media screen and (max-width: 1047px) {
    display: none;
  }
`

export const ClearBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  min-width: 24px;
  font-size: 13px;
  border: 1px solid ${({ theme }) => theme.colors.borderGrey};
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.backgroundGrey};

  &:hover {
    cursor: pointer;
  }
`

export const FormAction = styled.div`
  display: flex;
  flex-flow: row wrap-reverse;
  justify-content: center;
  align-content: center;
  gap: 40px;

  @media screen and (max-width: 1047px) {
    gap: 10px;
  }
`

export const ConfirmFormAction = styled(FormAction)`
  @media screen and (min-width: 1047px) {
    button {
      width: 225px;
    }
  }
`
