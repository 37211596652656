import { theme } from '@/styles/theme'
import { useEffect, useRef } from 'react'

export default function useFormInput() {
  const sliceMaxLength = (
    e: React.FormEvent<HTMLInputElement>,
    maxLength: number
  ) => {
    e.currentTarget.value = e.currentTarget.value.slice(0, maxLength)
  }

  const disableArrowKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case 'ArrowUp':
      case 'ArrowDown':
        e.preventDefault()
    }
  }

  const openImageWindow = (file: File) => {
    const url = URL.createObjectURL(file)
    window.open(url, 'window', 'width=300,height=400')
  }

  const dateParseString = (date: Date, isShowTime = true) => {
    let year = date.getFullYear()
    let month = ('0' + (date.getMonth() + 1)).slice(-2)
    let day = ('0' + date.getDate()).slice(-2)
    let hour = ('0' + date.getHours()).slice(-2)
    let minute = ('0' + date.getMinutes()).slice(-2)
    return isShowTime
      ? `${year}/${month}/${day} ${hour}:${minute}`
      : `${year}/${month}/${day}`
  }

  const formRadioClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.currentTarget.getElementsByTagName('input')[0]?.click()
    const siblings = e.currentTarget.parentNode?.children
    for (let i = 0; i < siblings!.length; i++) {
      siblings![i].setAttribute(
        'style',
        `border-color: #${theme.colors.borderGrey}`
      )
    }
    e.currentTarget.setAttribute(
      'style',
      `border-color: ${theme.colors.supayBlue}`
    )
  }

  const formCheckboxBtnClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.currentTarget.getElementsByTagName('input')[0]?.click()
  }

  const formCheckboxClick = (
    e: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => {
    e.stopPropagation()
    if (e.currentTarget.checked) {
      e.currentTarget.parentElement?.parentElement?.setAttribute(
        'style',
        `border-color: ${theme.colors.supayBlue}`
      )
    } else {
      e.currentTarget.parentElement?.parentElement?.setAttribute(
        'style',
        `border-color: ${theme.colors.borderGrey}`
      )
    }
  }

  const autoResizeTextArea = (value: string | undefined) => {
    const ref = useRef<HTMLTextAreaElement | null>(null)

    useEffect(() => {
      const element = ref.current
      if (!element) {
        return
      }

      const { borderTopWidth, borderBottomWidth } = getComputedStyle(element)

      element.style.height = 'auto'
      element.style.height = `calc(${element.scrollHeight}px + ${borderTopWidth} + ${borderBottomWidth})`
    }, [value])

    return ref
  }

  return {
    disableArrowKey,
    sliceMaxLength,
    openImageWindow,
    dateParseString,
    formRadioClick,
    formCheckboxBtnClick,
    formCheckboxClick,
    autoResizeTextArea,
  }
}
